import {
    Meeting,
MeetingTextEntry,
MeetingTextEntrySet,
Participant,
SummarizePrompt,
} from '../../models/models' 
import { useRx } from '../../utils/react-rxjs'
import { BehaviorSubject, Observable } from "rxjs"
import { Styleable, useRefSetup } from '../../utils/react-extensions'
import React, { useEffect, useState } from 'react'
import { parse } from 'uuid'
import { xhrCallback } from '@sentry/tracing/dist/browser/request'


export interface MeetingSummaryProps extends Styleable{
entries: Observable<Array<MeetingTextEntrySet>>
meetingId: number
}

const MeetingSummary: React.FC<MeetingSummaryProps> = props => {
const entries = useRx(() => props.entries, [], [props.entries])
const [aiPrompts, setAiPrompts] = useState(new Array<SummarizePrompt>())
const [aiSummary, setAiSummary] = useState("")
const [loading, setLoading] = useState(false)
const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        fetch(`/api/ai-summarize/`,{
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
          }).then(x => x.json())
          .then((prompts: Array<SummarizePrompt>) => {
              setAiPrompts(prompts)
          })
    }, [])


    useEffect(() => {
        fetch(`/api/meetings/${props.meetingId}/`,{
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
          }).then(x => x.json())
          .then((meeting: Meeting) => {
            if (meeting.ai_summary)
              setAiSummary(meeting.ai_summary)
          })
    }, [props.meetingId])
    
const getSummary = () =>{
    setLoading(true)
    setError(null)
    const selectedPrompt = document.querySelector("#selected-prompt") as HTMLSelectElement
    const promptId = selectedPrompt.value
    const prompt = aiPrompts.find((element) => element.id === parseInt(promptId))
    const wholeTranscript = entries.map((entry)=>entry.entries.map((entry2)=>entry2.content).join(" ")).join(" ")
    fetch(`/ai-summarize-prompts/ai-summarize`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken':
            document.getElementById('csrf')!.textContent!,
        },
        credentials: 'same-origin',
        body: JSON.stringify({
            prompt: prompt?.prompt ?? "Sumarize the following text:",
            transcript: wholeTranscript,
            meetingId: props.meetingId,
        }),
    }).then(response => {
        return response.json()})
    .then((jsonResponse: {"summary":string}) => {
        if (typeof jsonResponse.summary !== 'string')
            throw new Error("Error getting summary from AI. Please try again.")
        setAiSummary(jsonResponse.summary)
        setLoading(false)
    }).catch((error: any) => {
        setLoading(false)
        setError("Error getting summary from AI. Please try again.")
        console.log("error", error)
    })
}

return (
    <div className={`meeting-text d-flex flex-column p-3 ${
        props.className || ''
        }`}
        style={props.style}>
                <h2>Summary</h2>

        <div className='d-flex flex-column flex-grow-1 scrolling'>
            <p>{aiSummary}</p>
        </div>
            <div className="d-flex flex-row " >
                <select id="selected-prompt" className="btn-select btn btn-outline">
                    <option placeholder='Select Ai Prompt' key='Select Ai Prompt' value='Select Ai Prompt'> Select Ai Prompt</option>
                    {aiPrompts.map((element, index) => (
                        <option placeholder='Select Ai Prompt' key={element.name} value={element.id} selected={index === 0}>
                            {element.name}
                        </option>
                    ))}
                </select>
                {loading ? (  <button className="btn btn-primary" >
                    <div className="small-loader-spinner"></div>
                    </button>): (
                <button className="btn btn-primary" onClick={getSummary}>Get Summary</button>
                    )}
            </div>
            {error && 
            <p className="text-danger">{error}</p>
            }
    </div>
            
)
}

export default MeetingSummary