import React from 'react'
import { Observable, of } from 'rxjs'
import { useRx } from '../../utils/react-rxjs'
import { OngoingRecording } from '../../utils/recording'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { Meeting } from '../../models/models'
import MeetingNotesComponent from '../MeetingNotesComponent'

import { MicrophoneActivity } from '../microphone'
import { ReactProperty } from '../../utils/react-extensions'
import MeetingRecordComponent from './MeetingRecordComponent'
import {VideoUploading} from "../../utils/aws-upload";

export type MeetingRecordingComponentProps = {
  stream: MediaStream
  recording: OngoingRecording | null
  startRecording: (r: OngoingRecording) => void
  finishRecording: () => void
  name: ReactProperty<string>
  upload: VideoUploading | null
  willTranscribe: ReactProperty<boolean>
  isPublic: ReactProperty<boolean>
  meetingUpdates: Observable<Meeting>
}
const MeetingRecordingComponent: React.FC<MeetingRecordingComponentProps> =
  props => {
    const transcriptionMeter = document.getElementById(
      'transcription-meter'
    ) as HTMLMeterElement
    const availableTranscription =
      (transcriptionMeter.max - transcriptionMeter.value) * 1000 * 60 * 60
    const recordingLength = props.recording?.time() ?? 0
    if (
      props.willTranscribe.value &&
      recordingLength > availableTranscription
    ) {
      props.willTranscribe.set(false)
    }
    const meetingId = useRx(
      () =>
        props.meetingUpdates.pipe(
          map(x => x.id),
          distinctUntilChanged()
        ),
      null
    )
    return (
      <div className='d-flex gap-4 matchParent'>
        <div
          className='d-flex flex-column flex-grow-1 mw-50'
          style={{ minWidth: '26rem' }}
        >
          <label className='mb-2'>Meeting Name</label>
          <input
            type='text'
            value={props.name.value}
            onChange={(ev): void => props.name.set(ev.currentTarget.value)}
          />
          <div className='checkbox mt-auto'>
            <input
              type='checkbox'
              id='shouldTranscribe'
              disabled={recordingLength > availableTranscription}
              title={
                recordingLength > availableTranscription
                  ? "You don't have enough transcription time left."
                  : "Transcribe the recording. It'll take a minute, so be patient."
              }
              checked={props.willTranscribe.value}
              onChange={(ev): void =>
                props.willTranscribe.set(ev.currentTarget.checked)
              }
            />
            <label htmlFor='shouldTranscribe'>Transcribe this meeting</label>
          </div>
          <div className='checkbox '>
            <input
              type='checkbox'
              id='shouldBePublic'
              checked={props.isPublic.value}
              onChange={(ev): void =>
                props.isPublic.set(ev.currentTarget.checked)
              }
            />
            <label htmlFor='shouldBePublic'>Allow link sharing</label>
          </div>
          <MicrophoneActivity stream={props.stream} />
          <MeetingRecordComponent
            stream={props.stream}
            upload={props.upload}
            recording={props.recording}
            startRecording={props.startRecording}
            finishRecording={props.finishRecording}
          />
        </div>
        <div className='d-flex flex-column flex-grow-1'>
          <label>Notes</label>
          {meetingId && (
            <MeetingNotesComponent
              meetingId={meetingId}
              currentTime={of(0)}
              getCurrentTime={(): number => props.recording?.time() ?? 0}
              transcript={undefined}
            />
          )}
        </div>
      </div>
    )
  }

export default MeetingRecordingComponent
