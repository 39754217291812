import React, { useState } from 'react'
import { Observable } from 'rxjs'
import { useRx } from '../../utils/react-rxjs'
import { OngoingRecording } from '../../utils/recording'

import { useStateProperty } from '../../utils/react-extensions'
import testSound from '../../../assets/soundtest.wav'
import { useIdleTimer } from 'react-idle-timer'
import {VideoUploading} from "../../utils/aws-upload";
import {switchMap} from "rxjs/operators";

const idleWarningTimeout = 1000 * 60 * 60 // 1 hour
const idleTimeout = idleWarningTimeout + 5 * 1000 * 60 // 5 minutes of warning dialog

enum IdleDialogMode {
  NONE,
  WARNING,
  PAUSED,
}
const MeetingActivelyRecordingComponent: React.FC<{
  stream: MediaStream
  recording: OngoingRecording
  upload: VideoUploading
  onFinish: () => void
}> = props => {
  const partCompletion = useRx(() => props.upload.partCompletion.pipe(switchMap(x => x)), 0, [
    props.upload,
  ])
  const partsWaiting = useRx(() => props.upload.awaiting, 0, [props.upload])
  const [isPaused, setPaused] = useState(props.recording.paused)
  const idleDialogMode = useStateProperty(IdleDialogMode.NONE)

  useIdleTimer({
    timeout: idleWarningTimeout,
    onIdle: (): void => {
      if (!props.recording.paused) {
        const audio = new Audio(testSound)
        audio.play()
        idleDialogMode.set(IdleDialogMode.WARNING)
      }
    },
    onActive: () => {
      if (idleDialogMode.value === IdleDialogMode.WARNING) {
        idleDialogMode.set(IdleDialogMode.NONE)
      }
    },
  })
  useIdleTimer({
    timeout: idleTimeout,
    onIdle: (): void => {
      if (!props.recording.paused) {
        const audio = new Audio(testSound)
        audio.play()
        props.recording.pause()
        setPaused(true)
        idleDialogMode.set(IdleDialogMode.PAUSED)
      }
    },
  })
  let idleDialog = null
  switch (idleDialogMode.value) {
    case IdleDialogMode.NONE:
      break
    case IdleDialogMode.WARNING:
      idleDialog = (
        <dialog>
          <div className='flexVertical'>
            <h3>Are you still there?</h3>
            <p>
              We&apos;ll pause your recording unless you interact with the page
              in some way.
            </p>
          </div>
        </dialog>
      )
      break
    case IdleDialogMode.PAUSED:
      idleDialog = (
        <dialog>
          <div className='flexVertical'>
            <h3>We&apos;ve paused your recording</h3>
            <p>
              We detected no activity on the page for a while, and to stop you
              from using up all of your storage we paused recording.
            </p>
            <button
              className='btn btn-primary'
              onClick={(): void => {
                idleDialogMode.set(IdleDialogMode.NONE)
              }}
            >
              OK
            </button>
          </div>
        </dialog>
      )
      break
  }
  return (
    <div className='d-flex flex-column'>
      <progress className='mb-3' value={partCompletion} />
      {
        partsWaiting > 1 ? <span>+{partsWaiting-1}</span> : null
      }
      <button
        className='btn btn-primary mb-3'
        onClick={(): void => {
          if (isPaused) {
            props.recording.resume()
            setPaused(false)
            idleDialogMode.set(IdleDialogMode.NONE)
          } else {
            props.recording.pause()
            setPaused(true)
          }
        }}
      >
        {isPaused ? 'Resume Recording' : 'Pause Recording'}
      </button>
      <button
        className='btn btn-primary mb-3'
        onClick={(): void => {
          props.recording.end()
          props.onFinish()
        }}
      >
        Stop Recording
      </button>
      {idleDialog}
    </div>
  )
}

export default MeetingActivelyRecordingComponent
