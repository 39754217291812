import { Observable, Subject } from 'rxjs'
import {v4} from "uuid";

export const videoMimeType = 'video/webm;codecs=vp8,opus'
export const audioMimeType = 'audio/webm;codecs=opus'

export class OngoingRecording {
  extension: string
  mimeType: string
  stream: MediaStream
  mediaRecorder: MediaRecorder
  private internalData = new Subject<Blob>()
  startDate = Date.now()
  private pausedDate: number | null = Date.now()
  private skipTime = 0

  anyoneListening = 0
  started = false
  paused = true

  constructor(stream: MediaStream, mimeType?: string) {
    this.mimeType =
      mimeType ?? stream.getVideoTracks().length > 0
        ? videoMimeType
        : audioMimeType
    this.extension = 'webm'
    this.stream = stream
    this.setup(stream)
  }

  changeSources(newStream: MediaStream): void {
    if (this.stream == newStream) return
    this.mediaRecorder.stop()
    this.setup(newStream)
  }

  private setup(stream: MediaStream): void {
    const recorder = new MediaRecorder(stream, {
      mimeType: this.mimeType,
    })
    recorder.ondataavailable = (ev): void => {
      this.internalData.next(ev.data)
    }
    recorder.onerror = (ev): void => {
      console.error(ev.error)
    }
    this.mediaRecorder = recorder
  }

  get data(): Observable<Blob> {
    return this.internalData
  }

  pause(): void {
    if (this.paused) return
    this.paused = true
    this.mediaRecorder.pause()
    this.pausedDate = Date.now()
  }

  resume(): void {
    if (!this.paused) return
    this.skipTime += Date.now() - this.pausedDate!
    this.pausedDate = null
    if (this.started) this.mediaRecorder.resume()
    else {
      this.mediaRecorder.start(1000)
      this.started = true
    }
    this.paused = false
  }

  end(): void {
    this.mediaRecorder.stop()
    this.internalData.complete()
  }

  time(): number {
    if (this.pausedDate) {
      return (this.pausedDate - this.startDate - this.skipTime) / 1000
    }
    return (Date.now() - this.startDate - this.skipTime) / 1000
  }
}

export interface CompositeMediaStream {
  stream: MediaStream
  context: AudioContext
  sourceTracks: Array<MediaStreamTrack>
}

export function combineTracks(
  streams: Array<MediaStream>
): CompositeMediaStream {
  const inTracks: Array<MediaStreamTrack> = []
  const outTracks: Array<MediaStreamTrack> = []

  const context = new AudioContext()
  const combinedAudioTrack = context.createMediaStreamDestination()
  for (const stream of streams) {
    for (const track of stream.getAudioTracks()) {
      inTracks.push(track)
    }
    if (stream.getAudioTracks().length > 0) {
      const gain = context.createGain()
      gain.gain.value = 0.7
      context
        .createMediaStreamSource(stream)
        .connect(gain)
        .connect(combinedAudioTrack)
    }
  }
  for (const track of combinedAudioTrack.stream.getAudioTracks()) {
    outTracks.push(track)
  }
  for (const stream of streams) {
    const tracks = stream.getVideoTracks()
    if (tracks.length > 0) {
      for (const t of tracks) {
        outTracks.push(t)
      }
    }
  }
  return {
    stream: new MediaStream(outTracks),
    context: context,
    sourceTracks: inTracks,
  }
}

export function listenEndOnAnyTrack(
  tracks: Array<MediaStreamTrack>,
  action: (ev: Event) => void
): () => void {
  for (const track of tracks) {
    track.addEventListener('ended', action)
  }
  return (): void => {
    for (const track of tracks) {
      track.removeEventListener('ended', action)
    }
  }
}
