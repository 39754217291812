import { Chime } from 'aws-sdk'

export interface ChimeJoinInfo {
  meetingInfo: Chime.CreateMeetingResponse
  attendeeInfo: Chime.CreateAttendeeResponse
}

export interface Room {
  id: number
  slug: string
  user: number
  meeting?: number
}

export interface SocketMessage {
  type:
    | 'Meeting'
    | 'Error'
    | 'Participant'
    | 'Room'
    | 'MeetingTextEntry'
    | 'ParticipantToken'
  data?: string
  id?: number
}

export interface Meeting {
  id: number
  guid?: string
  name: string
  multipart_upload_id?: string
  multipart_upload_key?: string
  owner: number
  start_datetime?: Date
  end_datetime?: Date
  start_transcription_datetime?: Date
  end_transcription_datetime?: Date
  provider?: string
  meeting_url: string
  transcription?: TranscriptionResult
  ai_summary?: string
  will_transcribe: boolean
  listing_status: ListingStatus
  recording?: string
  recording_s3?: string
  recording_length: number // milliseconds
}

export interface MeetingRaw {
  id: number
  guid?: string
  name: string
  multipart_upload_id?: string
  multipart_upload_key?: string
  owner: number
  start_datetime?: string
  end_datetime?: string
  chime_start?: string
  start_transcription_datetime?: string
  end_transcription_datetime?: string
  provider?: string
  meeting_url: string
  transcription?: string
  ai_summary?: string
  will_transcribe: boolean
  listing_status: ListingStatus
  recording?: string
  recording_s3?: string
  recording_length: string
}

export namespace Meeting {
  export function equal(a: Meeting, b: Meeting): boolean {
    return (
      a.id === b.id &&
      a.guid === b.guid &&
      a.name === b.name &&
      a.multipart_upload_id === b.multipart_upload_id &&
      a.multipart_upload_key === b.multipart_upload_key &&
      a.owner === b.owner &&
      a.start_datetime === b.start_datetime &&
      a.end_datetime === b.end_datetime &&
      a.start_transcription_datetime === b.start_transcription_datetime &&
      a.end_transcription_datetime === b.end_transcription_datetime &&
      a.provider === b.provider &&
      a.meeting_url === b.meeting_url &&
      a.transcription === b.transcription &&
      a.ai_summary === b.ai_summary &&
      a.will_transcribe === b.will_transcribe &&
      a.listing_status === b.listing_status &&
      a.recording === b.recording &&
      a.recording_s3 === b.recording_s3
    )
  }

  function jsonifyDate(date: Date | undefined): string | undefined {
    if (!date) return undefined
    return date.toISOString()
  }

  function parseDate(date: string | undefined): Date | undefined {
    if (!date) return undefined
    return new Date(date)
  }

  function jsonifyDuration(durationMs: number): string {
    let remaining = durationMs
    const hours = Math.floor(remaining / durationPartTable[0])
    remaining -= hours * durationPartTable[0]
    const minutes = Math.floor(remaining / durationPartTable[1])
    remaining -= minutes * durationPartTable[1]
    const seconds = remaining / durationPartTable[2]
    return `${hours}:${minutes}:${seconds}`
  }

  function parseDuration(value: string): number {
    let ms = 0
    try {
      let partIndex = 0
      for (const part of value.split(':')) {
        ms += parseFloat(part) * durationPartTable[partIndex++]
      }
    } catch (e) {
      console.error(e)
    }
    return ms
  }

  const durationPartTable = [1000 * 60 * 60, 1000 * 60, 1000]

  export function parse(item: MeetingRaw): Meeting {
    let parsedTranscription: TranscriptionResult | undefined = undefined
    if (item.transcription) {
      parsedTranscription = JSON.parse(item.transcription)
      if (!TranscriptionResult.checksOut(parsedTranscription)) {
        parsedTranscription = undefined
        console.warn(
          `Transcript is jank: ${TranscriptionResult.wrong(
            parsedTranscription
          )}`
        )
      }
    }
    return {
      ...item,
      start_datetime: parseDate(item.start_datetime),
      end_datetime: parseDate(item.end_datetime),
      start_transcription_datetime: parseDate(
        item.start_transcription_datetime
      ),
      end_transcription_datetime: parseDate(item.end_transcription_datetime),
      transcription: parsedTranscription,
      recording_length: parseDuration(item.recording_length),
    }
  }

  export function toJson(item: Meeting): MeetingRaw {
    return {
      ...item,
      start_datetime: jsonifyDate(item.start_datetime),
      end_datetime: jsonifyDate(item.end_datetime),
      start_transcription_datetime: jsonifyDate(
        item.start_transcription_datetime
      ),
      end_transcription_datetime: jsonifyDate(item.end_transcription_datetime),
      transcription: JSON.stringify(item.transcription),
      recording_length: jsonifyDuration(item.recording_length),
    }
  }

  export function toJsonPartial(item: Partial<Meeting>): Partial<MeetingRaw> {
    return {
      ...item,
      start_datetime: jsonifyDate(item.start_datetime),
      end_datetime: jsonifyDate(item.end_datetime),
      start_transcription_datetime: jsonifyDate(
        item.start_transcription_datetime
      ),
      end_transcription_datetime: jsonifyDate(item.end_transcription_datetime),
      transcription: JSON.stringify(item.transcription),
      recording_length: item.recording_length
        ? jsonifyDuration(item.recording_length)
        : '0',
    }
  }
}

export enum ListingStatus {
  FORCED_PRIVATE,
  PRIVATE,
  UNLISTED,
}

export interface TranscriptionResult {
  jobName: string
  accountId: string
  results: {
    transcripts: Array<{ transcript: string }>
    speaker_labels?: {
      speakers: number
      segments: Array<{
        start_time: string
        speaker_label: string
        end_time: string
        items: Array<{
          start_time: string
          end_time: string
          speaker_label: string
        }>
      }>
    }
    items: Array<{
      start_time?: string
      end_time?: string
      alternatives: Array<{
        confidence: string
        content: string
      }>
      type: 'pronunciation' | 'punctuation'
    }>
    segments?: Array<{
      start_time: string
      end_time: string
      alternatives: Array<{
        transcript: string
        items: Array<{
          start_time?: string
          end_time?: string
          confidence: string
          type: 'pronunciation' | 'punctuation'
          content: string
        }>
      }>
    }>
  }
  status: 'COMPLETED'
}

export namespace TranscriptionResult {
  export function checksOut(t: any): t is TranscriptionResult {
    return wrong(t) === undefined
  }

  export function wrong(t: any): string | undefined {
    if (typeof t !== 'object') return 'Root is not object.'
    if (t === undefined) return 'Root is undefined.'
    if (t === null) return 'Root is null.'
    if (typeof t['jobName'] !== 'string') return 'jobName is not a string.'
    if (typeof t['accountId'] !== 'string') return 'accountId is not a string.'
    if (typeof t['results'] !== 'object') return 'results is not an object.'
    if (!Array.isArray(t['results']['transcripts']))
      return 'results.transcripts is not an array.'
    if (
      t['results']['items'] !== undefined &&
      !Array.isArray(t['results']['items'])
    )
      return 'results.items is not an array.'
    if (
      t['results']['segments'] !== undefined &&
      !Array.isArray(t['results']['segments'])
    )
      return 'results.segments is not an array.'
    if (
      t['results']['speaker_labels'] !== undefined &&
      !Array.isArray(t['results']['speaker_labels']['segments'])
    )
      return 'results.speaker_labels.segments is not an array.'
    return undefined
  }
}

export interface Participant {
  id: number
  meeting?: number
  room?: number
  user?: number
  name: string
  call_id?: string
  transcription_index?: number
  authorized: boolean
}

export interface ParticipantWithToken extends Participant {
  token: string
}

export interface MeetingTextEntry {
  id: number
  meeting: number
  participant?: number
  content: string
  time_start: number
  time_end: number
}

export interface MeetingTextEntrySet {
  participant?: number
  entries: Array<MeetingTextEntry>
}

export interface SummarizePrompt {
  id: number
  name: string
  owner: number
  prompt?: string
  created_at: string
}
